








import "formdata-polyfill";
import { Component, Vue } from "vue-property-decorator";
import Topbar from "./components/layout/Topbar.vue";
import Footer from "./components/layout/Footer.vue";

@Component({
  components: {
    Topbar,
    Footer,
  },
})
export default class App extends Vue {}
