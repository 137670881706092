
































































import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";
import { stringify } from "qs";
import { formDataToObject } from "@/utils/formUtils";

@Component
export default class TimereportDialog extends Vue {
  @Prop() show: boolean;
  @Prop() mode: string;

  message = "";
  success = false;
  error = false;
  lastData: FormData | null = null;

  get title() {
    switch (this.mode) {
      case "approve":
        return "Är du säker?";
      case "approve_comment":
        return "Vänligen ange kommentar nedan:";
      case "reject":
        return "Vänligen kommentera orsaken nedanför.\n Vi kommer att utreda denna tidrapporten manuellt.";
      default:
        return "";
    }
  }

  get canSubmit() {
    return this.mode === "approve" || this.message;
  }

  get showSubmitBtn() {
    return !this.success && !this.error;
  }

  get showRetryBtn() {
    return Boolean(this.error);
  }

  get showContent() {
    return !this.success && !this.error && (this.mode === "reject" || this.mode === "approve_comment");
  }

  get isCommentRequired() {
    return this.mode !== "approve";
  }

  get dialogClasses() {
    return this.show ? "dialog mdl-dialog tr-dialog open" : "dialog mdl-dialog tr-dialog";
  }

  close() {
    this.message = "";
    this.error = false;
    this.success = false;
    this.$emit("close");
  }

  submitForm(e: any) {
    e.preventDefault();

    var formData = new FormData(e.target);
    var data = formDataToObject(formData);
    this.lastData = data;
    this.submit(data);
  }

  resubmit() {
    if (this.lastData) {
      this.submit(this.lastData);
    }
  }

  submit(data: any) {
    // console.log(this.$router);
    const baseUrl = `${process.env.VUE_APP_TIMEREPORT_SERVICE_URL}/api/public/v1/review`;
    const query = stringify({
      key: this.$route.query.key,
      comment: data.comment,
    });

    const url =
      this.mode === "approve" || this.mode === "approve_comment"
        ? `${baseUrl}/approve?${query}`
        : `${baseUrl}/reject?${query}`;

    axios
      .get(url)
      .then(() => {
        this.error = false;
        this.success = true;
        this.$emit("closeDone", "approve");
      })
      .catch(() => {
        this.error = true;
        this.success = false;
      });
  }
}
