
























import Vue from "vue";
import Component from "vue-class-component";
import Toolbar from "@/components/misc/Toolbar.vue";
import TimereportDialog from "@/components/timereport_approve/TimereportDialog.vue";
import IframeViewer from "@/components/misc/IframeViewer.vue";
import { stringify } from "qs";
import axios from "axios";

@Component({
  name: "TimereportScreen",
  components: { IframeViewer, TimereportDialog, Toolbar },
})
export default class TimereportScreen extends Vue {
  mode: "approve" | "approve_comment" | "reject" | null = null;
  doneMode: "approve" | "approve_comment" | "reject" | null = null;
  done = false;
  canReview = false;
  isNationalProcurement = false;
  cantReviewReason = "";
  reviewedBy = "";
  reviewedOn = "";

  mounted() {
    this.getCanReview();
  }

  get isReviewed(): boolean {
    return Boolean(this.reviewedBy) && Boolean(this.reviewedOn);
  }

  get showDialog() {
    return Boolean(this.mode);
  }

  get iframeSrc() {
    const id = this.$route.params.id;
    return `${process.env.VUE_APP_CRM_URL}/files/${id}/pdf?mode=view`;
  }

  get isDisabledBtn() {
    return this.done || !this.canReview;
  }

  getCanReview() {
    const baseUrl = `${process.env.VUE_APP_TIMEREPORT_SERVICE_URL}/api/public/v1/review/canReview`;
    const query = stringify({
      key: this.$route.query.key,
    });

    const url = `${baseUrl}?${query}`;

    axios
      .get(url)
      .then((res) => {
        const data = res.data || {};

        this.canReview = data.canReview;
        this.isNationalProcurement = data.isNationalProcurement;
        this.cantReviewReason = this.canReview ? "" : data.reason;
        this.reviewedBy = data.reviewedBy;
        this.reviewedOn = data.reviewedOn;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  approve() {
    this.mode = "approve";
  }

  approve_comment() {
    this.mode = "approve_comment";
  }

  reject() {
    this.mode = "reject";
  }

  close() {
    this.mode = null;
  }

  closeDone(mode: any) {
    this.done = true;
    this.doneMode = mode;
    this.mode = null;
  }
}
