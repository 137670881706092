








import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "IframeViewer",
  props: {
    iframeSrc: String,
  },
})
export default class IframeViewer extends Vue {
  @Prop()
  fileSrc: string;

  get url() {
    return `${this.fileSrc}#toolbar=0&navpanes=0&scrollbar=0&view=FitH`;
  }
}
