import Vue from "vue";
import VueRouter from "vue-router";
import NotFoundScreen from "./screens/NotFoundScreen.vue";
import TimereportScreen from "./screens/TimereportScreen.vue";

Vue.use(VueRouter);

export const createRouter = () =>
  new VueRouter({
    mode: "history",
    routes: [
      {
        path: "/timereport/:id",
        component: TimereportScreen,
      },
      {
        path: "/:pathMatch(.*)*",
        component: NotFoundScreen,
      },
    ],
  });
